import axios from 'axios';

const restClient = axios.create({
  baseURL: 'https://my-json-server.typicode.com/Code-Pop/Real-World_Vue-3',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default {
  getEvents() {
    return restClient.get('/events');
  },

  getEvent(id) {
    return restClient.get('/events/' + id);
  },
};
