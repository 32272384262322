<template>
  <div class="events">
    <img alt="Vue logo" src="../assets/logo.png" />
    <h1>Events For Good</h1>
    <div class="flex">
      <EventCard v-for="event in events" :key="event.id" :event="event" />
    </div>
  </div>
</template>

<script>
import EventService from '../services/EventService';

// @ is an alias to /src
import EventCard from '@/components/EventCard.vue';

export default {
  name: 'EventList',
  components: {
    EventCard,
  },

  created() {
    EventService.getEvents()
      .then(response => (this.events = response.data))
      .catch(error => console.error(error.response.data));
  },

  data: () => ({
    events: [],
  }),
};
</script>

<style>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
