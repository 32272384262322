<template>
  <div v-if="event !== null">
    <h1>{{ event.title }}</h1>
    <p>{{ event.time }} on {{ event.date }} @ {{ event.location }}</p>
    <p>{{ event.description }}</p>
  </div>
</template>

<script>
import EventService from '../services/EventService';

export default {
  /**
   * This id is injected by Vue Router, to make this work
   * all we have to do is to set "props: true" in the route
   */
  props: ['id'],

  data: () => ({
    event: null,
  }),

  created() {
    EventService.getEvent(this.id)
      .then(response => (this.event = response.data))
      .catch(error => console.error(error.response.data));
  },
};
</script>

<style></style>
